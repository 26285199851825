<template>
    <div style="display:flex; min-height:42px; max-height:42px" :class="isfocus ? 'border border-primary2' : 'border border-gray2'" class="search_customer_box  text-gray-500 bg-white rounded-md text-sm px-2 py-1 items-center">
            <span @click="searchIconClick()" class="mr-2 cursor-pointer"><img height="20px" width="20px" src="@/assets/images/svg/gray-search.svg" alt=""></span>
            <input class="focus:outline-none w-96"
            type="search"
            name=""
            :placeholder="`${ placeholderText ? placeholderText : 'Search...'}`"
            id="search_Input"
            v-model="searchVal"
            :autofocus="isfocus"
            @input="callSearch"
            @focus="isfocus = true"
            @blur="isfocus = false"
            >
         </div>
</template>
<script>
export default {
    props: ["placeholderText", 'inputvalue'],
  data() {
    return {
        searchVal: '',
        isfocus: false
    };
  },
  watch: {
    inputvalue: {
      handler () {
        if (this.inputvalue !== '') {
          this.searchVal = this.inputvalue
        }
      }
    },
    placeholderText: {
      handler () {
        if (this.placeholderText === '') {
          document.getElementById("search_Input").focus()
        } else {
         document.getElementById("search_Input").focus()
        }
      }
    }
  },
  mounted() {},
  methods: {
    callSearch () {
        this.$emit('searchCall', this.searchVal)
    },
    searchIconClick () {
        document.getElementById('search_Input').focus()
    }
  },
};
</script>
<style scoped>
.goTop_btn {
    position: fixed;
    bottom: 25px;
    right: 25px;
    z-index: 1000;
}
</style>
