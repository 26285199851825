<template>
  <div class=" bg-white -mt-4">
    <div class="cust_card p-2 m-2 ">
        <div class="flex items-center">
          <p class="heading-3 text-primary cursor-pointer" @click="$router.push({name: 'ProfileDetail'})">Setting</p>
          <p class="heading-3 text-gray3 px-3">></p>
          <p class="heading-3 text-gray4">User List</p>
        </div>
    </div>
    <div class=" rounded px-2 py-2  bg-white cust_card m-2 " style="overflow: visible;">
      <div class="pt-1">
        <div class=" flex justify-between items-center">
          <div class="pb-2">
            <button class="mr-3 text-sm border hover:border-info py-2.5 text-text1 rounded-md list_btn" :class="statusVal ? 'bg-info border-info' : 'bg-white border-gray2'" @click="statusChip('active')">Active</button>
            <button class="mr-3 text-sm border hover:border-info py-2.5  text-text1 rounded-md list_btn" :class="!statusVal ? 'bg-info border-info' : 'bg-white border-gray2'" @click="statusChip('inactive')">Inactive</button>
          </div>
          <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'primary2'" :btnText="'Add User'" @buttonAction="$router.push({name: 'addNewStaff', params: {staffId: 0}})"/>
        </div>
        <div class="mb-2 flex">
          <div class="max-w-max mr-4">
            <div class="select_button text-sm text-gray3">
            <SimpleDropdown :dropdownList="items" @callApiAsperSelectValue1="getLeaveDataAsPerPagination1"/>
            </div>
          </div>
          <SearchTextInput class="searchInput" @searchCall="searchCall" :placeholderText="`Search User...`"/>
        </div>
      </div>
      <div class="">
        <div class="w-auto" >
          <div class="progress-bar" v-if="searchLoader">
            <div class="progress-bar-value"></div>
          </div>
        </div>
        <div style="overflow-y: auto; white-space: nowrap;" class="table_containder max-w-max h-full">
          <div class="text-text2  min-w-min heaer_row border border-gray1 font-bold flex heading-7  px-3 py-3 items-center">
            <div class="name_col  flex items-center">
              <span class="cursor-pointer" @click="sortByColumn(0)">{{titleObj[0].title}} </span>
              <img v-if="titleObj[0].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
              <img v-if="titleObj[0].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
            </div>
            <div class="dept_col  flex items-center">
              <span class="cursor-pointer" @click="sortByColumn(1)">{{titleObj[1].title}}</span>
              <img class="pl-1" v-if="titleObj[1].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
              <img class="pl-1" v-if="titleObj[1].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
            </div>
            <div class="emp_col  flex items-center">
              <span class="cursor-pointer" @click="sortByColumn(2)">{{titleObj[2].title}}</span>
              <img class="pl-1" v-if="titleObj[2].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
              <img class="pl-1" v-if="titleObj[2].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
            </div>
            <div class="added2_col  flex items-center">
                <span class="cursor-pointer" @click="sortByColumn(3)">{{titleObj[3].title}}</span>
                <img class="pl-1" v-if="titleObj[3].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
              <img class="pl-1" v-if="titleObj[3].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
            </div>
            <div class="added_col  flex items-center">
                <span class="cursor-pointer" @click="sortByColumn(4)">{{titleObj[4].title}}</span>
                <img class="pl-1" v-if="titleObj[4].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
              <img class="pl-1" v-if="titleObj[4].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
            </div>
          </div>
          <div v-for="(data, index) in userList" :key="index" class="min-w-min flex border-r border-l border-b border-gray1  bg-white  text-sm px-3 py-2 items-center cursor-pointer hover:bg-gray-50" @click="redirectToDetail(data.staffId)">
            <div class="name_col">
              <div class="flex items-center">
                <div class="max-w-max rounded-full mr-2 border border-gray2">
                <img class="rounded-full row_user_svg" :src="data.profileImagePath.medium"  v-if="data.profileImagePath.medium !== ''">
                <img class="rounded-full h-8 w-8" src="@/assets/images/svg/user-color.svg"  v-if="data.profileImagePath.medium === ''">
              </div>
              <span class="heading-4  text-text1 ">{{data.staffFirstName}} {{data.staffLastName}}</span>
              </div>
            </div>
            <div class="dept_col">
              <span class="heading-4 text-text1" v-if="data.staffCellNumber !== ''">{{data.staffCellNumber | phone}}</span>
              <span class="pl-4" v-else>--</span>
            </div>
            <div class="emp_col">
              <span class="heading-4 text-text1" v-if="data.staffEmail !== ''">{{data.staffEmail}}</span>
              <span class="pl-4" v-else>--</span>
            </div>
            <div class="added2_col">
              <span class="heading-4 text-text1">{{data.isAdmin ? 'Yes' : 'No'}}</span>
            </div>
            <div class="added_col">
              <span class="heading-4 text-text1" v-if="data.lastLoginTime !== ''">{{data.lastLoginTime | formatDataeTime}}</span>
              <span class="pl-4" v-else>--</span>
            </div>
          </div>
        </div>
        <div class="layout my-2" v-if="userList.length === 0">
          <div class="flex p-3 text-sm text-primary bg-white border-blue-100 border-2 rounded-lg  items-center" role="alert">
            <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <div>
              <p class=" font-medium heading-4">No record found.</p>
            </div>
          </div>
        </div>
      </div>
    <div class="pt-2">
      <Pagination
        :totalDataCount="totalEmpCount"
        :resetPagination="resetPaginationValues"
        @callApiAsPerPagination="getCustomerDataFromPagination"/>
    </div>
  </div>
</div>
</template>
<script>
import SimpleDropdown from '@/View/components/simpleDropdown.vue'
import Pagination from '@/View/components/pagination.vue'
import deboucneMixin from '@/mixins/debounce.js'
import SearchTextInput from '@/View/components/searchTextInput.vue'
import Button from '@/View/components/globalButton.vue'
import API from '@/api/App.js'
export default {
  components: {
    Button,
    SimpleDropdown,
    Pagination,
    SearchTextInput
  },
  mixins: [deboucneMixin],
  data () {
    return {
      statusVal: true,
      searchLoader: false,
      resetPaginationValues: false,
      searchForCustomer: '',
      titleObj: [
        {title: 'Name', icon: 'fas fa-sort-amount-up', sortName: 'staffFirstName', sortVal: 'DESC'},
        {title: 'Cell Number', icon: '', sortName: 'staffCellNumber', sortVal: ''},
        {title: 'Email Address(Login) ', icon: '', sortName: 'staffEmail', sortVal: ''},
        {title: 'IsAdmin', icon: '', sortName: 'isAdmin', sortVal: ''},
        {title: 'Last Activity', icon: '', sortName: 'lastLoginTime', sortVal: ''},
    ],
      filterObject: {
        start: 0,
        length: 20,
        isActive: true,
        sortBy: 'staffFirstName',
        sortOrder: 'DESC'
      },
      totalEmpCount: 0,
      page: 1,
      items: [20, 25, 30, 50, 100],
      paginationData: 20,
      userList: []
    }
  },
  mounted () {
    document.title = 'Staff List'
    this.getContactListDeboucne = this.debounce(function () {
      this.resetPaginationValues = true
    }, 500)
    this.getUserList()
  },
  watch: {
    searchForCustomer: {
      handler () {
        if (this.searchForCustomer === null) {
          this.searchForCustomer = ''
        }
      }
    },
    titleObj: {
      handler () {
        console.log('titleObj 123', this.titleObj)
      }
    },
    paginationData: {
      handler () {
        this.filterObject.length = this.paginationData
        this.showlistLoader = true
        this.getUserList(this.showlistLoader)
      },
      deep: true
    }
  },
  methods: {
    searchCall (data) {
      this.searchForCustomer = data
      this.getContactListDeboucne()
    },
    getCustomerDataFromPagination (data) {
      this.filterObject.start = data.offset
      this.filterObject.length = data.limit
      let showlistLoader = false
      if (data.offset !== 0) {
        showlistLoader = true
      }
      this.getUserList(showlistLoader)
    },
    sortByColumn (index) {
      for (let i = 0; i < this.titleObj.length; i++) {
        if (i === index) {
          if (this.titleObj[i].icon === '') {
            this.titleObj[i].icon = 'fas fa-sort-amount-down'
            this.titleObj[i].sortVal = 'DESC'
            this.filterObject.sortOrder = 'DESC'
          } else if (this.titleObj[i].icon === 'fas fa-sort-amount-down') {
            this.titleObj[i].icon = 'fas fa-sort-amount-up'
            this.titleObj[i].sortVal = 'ASC'
            this.filterObject.sortOrder = 'ASC'
          } else {
            this.titleObj[i].icon = 'fas fa-sort-amount-down'
            this.filterObject.sortOrder = 'DESC'
            this.titleObj[i].sortVal = 'DESC'
          }
        } else {
          this.titleObj[i].icon = ''
          this.titleObj[i].sortVal = ''
        }
      }
      this.filterObject.sortBy = this.titleObj[index].sortName
      this.getUserList(true)
    },
    redirectToDetail (staffId) {
      this.$router.push({name: 'staffDetail', params: {staffId: staffId}})
    },
    toTop () {
      this.$vuetify.goTo(0)
    },
    statusChip (val) {
      console.log(val)
      if (val === 'active') {
        this.statusVal = true
        this.filterObject.isActive = true
      } else if (val === 'inactive') {
        this.statusVal = false
        this.filterObject.isActive = false
      }
      this.getUserList()
    },
    getLeaveDataAsPerPagination1 (data) {
      this.filterObject.length = data
      this.getUserList()
    },
    getUserList (showlistLoader) {
      this.searchLoader = true
      if (showlistLoader && this.searchForCustomer !== '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
      if (this.searchForCustomer === '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
      API.getStaffList(
        this.filterObject.start,
        this.filterObject.length,
        this.searchForCustomer,
        this.filterObject.sortBy,
        this.filterObject.sortOrder,
        this.filterObject.isActive,
        response => {
          this.resetPaginationValues = false
          this.userList = response.Data.TableRow === null ? [] : response.Data.TableRow
          this.totalEmpCount = response.Data.count
          console.log('count', this.totalEmpCount)
          this.searchLoader = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    }
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
.table_containder {
  padding: 1px;
}
.name_col {
  min-width: 400px !important;
  width: 400px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.dept_col {
  min-width: 320px !important;
  width: 320px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.emp_col {
  min-width: 320px !important;
  width: 320px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.added2_col {
  min-width: 200px !important;
  width: 200px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.added_col {
  min-width: 320px !important;
  width: 320px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.list_btn {
  min-width: 77px !important;
  max-width: 77px;
}
.row_user_svg {
  height: 36px;
  width: 36px;
}

.progress-bar {
  height: 2px;
  background-color: rgb(18,149,186);
  width: 1586px;
  overflow: hidden;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: rgb(121, 200, 221);
  animation: indeterminateAnimation 2s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
::placeholder {
  font-size: 16px;
}
.searchInput {
  height: 42px;
}
</style>
