<template>
  <div class="relative" >
      <div :class="display ? 'border border-primary' : 'border border-gray2'" class="flex justify-center select_btn heading-5  bg-white w-fit rounded-md py-2.5 px-3" @click.stop="showPopup">
        <div class="w-full flex justify-between">
          <div class="flex">
            {{selectedValue}}
          </div>
          <span class="">
            <img v-if="display" width="20px" height="20px" src="@/assets/images/svg/up-angle.svg" alt="down-angle">
            <img v-else width="20px" height="20px" src="@/assets/images/svg/down-angle.svg" alt="down-angle">
          </span>
        </div>
      </div>
      <div class="absolute flex-col w-full overflow-auto shadow-2xl text-text2" v-show="display" v-click-outside="hidePopup">
       <div v-for="(data, index) in domainData" :key="index" class="justify-center flex shadow hover:bg-gray-100  cursor-pointer p-2 pl-2  border-b hover:bg-teal-100 border-transparent border-l-2 relative hover:border-teal-100" :class="data === selectedValue ? 'bg-blue-100' : 'bg-white' " @click="selectIndex(index)">
          <div class="font-semibold">
            {{ data }}
          </div>
       </div>
      </div>
    </div>
</template>
<script>
import Vue from 'vue'
Vue.directive('click-outside',{
  bind: function (el, binding, vnode) {
      el.eventSetDrag = function () {
          el.setAttribute('data-dragging', 'yes');
      }
      el.eventClearDrag = function () {
          el.removeAttribute('data-dragging');
      }
      el.eventOnClick = function (event) {
          var dragging = el.getAttribute('data-dragging');
          // Check that the click was outside the el and its children, and wasn't a drag
          if (!(el == event.target || el.contains(event.target)) && !dragging) {
              // call method provided in attribute value
              vnode.context[binding.expression](event);
          }
      };
      document.addEventListener('touchstart', el.eventClearDrag);
      document.addEventListener('touchmove', el.eventSetDrag);
      document.addEventListener('click', el.eventOnClick);
      document.addEventListener('touchend', el.eventOnClick);
  }, unbind: function (el) {
      document.removeEventListener('touchstart', el.eventClearDrag);
      document.removeEventListener('touchmove', el.eventSetDrag);
      document.removeEventListener('click', el.eventOnClick);
      document.removeEventListener('touchend', el.eventOnClick);
      el.removeAttribute('data-dragging');
  },
})
export default {
  data() {
    return {
      domainData: [],
      sendData: [],
      category: '',
      display:false,
      selectedValue: ''
    };
  },
  props: ['dropdownList'],
  watch: {},
  mounted() {
      this.domainData = this.dropdownList
      if (this.dropdownList.length > 0) {
      this.selectedValue = this.domainData[0]
    }
  },
  methods: {
    showPopup() {
      this.display = true
    },
    hidePopup() {
      this.display = false
    },
    selectIndex(indexA) {
      console.log('check1');
        this.display = false
        this.selectedValue = this.domainData[indexA]
        this.$root.$emit('setPaginationValueAsperVlue', this.selectedValue)
    },
  },
};
</script>
<style>
.top-100 {
  top: 100%;
}
.bottom-100 {
  bottom: 100%;
}
.max-h-select {
  max-height: 300px;
}

.heightAuto {
  min-height: 40px;
}
</style>
