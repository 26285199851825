<template>
  <div class="p-1 m-1" v-if="showPageination">
    <div class="autoPadding">
      <div class="text-right">
        <div class="mb-1 pb-1" >
          <div class="text-right">
            <!-- <span label  class="w-fit outline-gray-200  outline-gray rounded text-sm px-2 py-2 mb-2 mr-1" :outlined="pageNumber !== 1"> -->
              <button :disabled="(pageNumber === 1)" :class="pageNumber !== 1 ? 'bg-primary text-white' : 'bg-gray2'" class=" outline outline-1 pagination-button text-white w-fit outline-gray-200  outline-gray rounded heading-5 mr-3"  @click="changePageHandler(1)">
                1
              </button>
            <!-- </span> -->
            <button
              v-if="totalNoOfPages > 2"
              x-small
              height="30px"
              :class="pageNumber > 1 ? 'bg-primary text-white' : 'bg-gray2 text-white'"
              class="w-fit outline-gray rounded heading-5  mr-3"
              @click="previousPage"
              :disabled="(pageNumber === 1)"
            >
              <i class="fas fa-chevron-left"></i>
            </button>
            <input v-if="totalNoOfPages > 2" type="number" v-model.number="selectedPageNumber" class="page-number-input mr-3" @change="updatePageNumber()"/>
            <button
              v-if="totalNoOfPages > 2"
              x-small
              height="30px"
              :class="pageNumber < totalNoOfPages ? 'bg-primary text-white' : 'bg-gray2 text-white'"
              class="outline outline-1 w-fit outline-gray-200  outline-gray rounded mr-3"
              @click="nextPage"
              :color="`primary`"
              :disabled="(pageNumber >= totalNoOfPages)"
            >
              <i class="fas fa-chevron-right heading-5"></i>
            </button>
            <!-- <span v-if="totalNoOfPages !== 1" label class="w-fit outline-gray-200 bg-white outline outline-1 outline-gray rounded text-sm px-2 py-1 mb-2 mr-1" :outlined="pageNumber !== totalNoOfPages"> -->
              <button v-if="totalNoOfPages !== 1" :class="pageNumber !== totalNoOfPages ? 'bg-primary text-white' : 'bg-gray2'" class="pagination-button text-white w-fit outline-gray-200  outline-gray rounded text-sm mr-3"  @click="changePageHandler(totalNoOfPages)">
                {{totalNoOfPages}}
              </button>
            <!-- </span> -->
            <!-- <select class="border border-gray2  w-fit outline-gray-200  outline-gray rounded text-sm px-2 py-1" name="" id=""
              v-model="dataLimit"
              @change="dataLimitChangeHandler(dataLimit)"
              >
              <option>20</option>
              <option  v-for="(data, index) in items" :key="index" v-bind:value="data">{{data}}</option>
            </select> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      start: 0,
      selectedPageNumber: 1,
      pageNumber: 1,
      totalNoOfPages: 0,
      dataLimit: 20,
      items: [25, 30, 50, 100],
      showPageination: true,
      firstindex: [],
      lastindex: [],
      firstArray: [],
      secondArray: [],
      showFirstGap: false,
      showSecondGap: false,
      pageNumone: true,
      dot: true,
      showdot: true
    }
  },
  props: ['totalDataCount', 'resetPagination', 'pagination', 'isDetailsListings', 'defaultPerPage'],
  beforeMount () {
  },
  mounted () {
    this.$root.$on("setPaginationValueAsperVlue", (data) => {
      console.log('check call pagination', data);
      this.dataLimit = data
      if (data) {
        this.dataLimitChangeHandler(data)
      }
    });
    if (this.isDetailsListings === true) {
      this.dataLimit = 5
    } else {
      this.dataLimit = 20
    }
    if (this.defaultPerPage) {
      let findIndex = this.items.findIndex(x => x === this.defaultPerPage)
      if (findIndex < 0) {
        this.items.push(this.defaultPerPage)
      }
      this.dataLimit = this.defaultPerPage
    }
    if (this.pagination) {
      this.pageNumber = (this.pagination.offset / this.pagination.limit) + 1
      this.start = this.pagination.offset
    }
    this.totalNoOfPages = Math.ceil(this.totalDataCount / this.dataLimit)
    if (this.totalDataCount <= this.dataLimit) {
      this.showPageination = false
    } else {
      this.showPageination = true
    }
  },
  watch: {
    totalDataCount: {
      handler () {
        this.totalNoOfPages = Math.ceil(this.totalDataCount / this.dataLimit)
        if (this.totalDataCount <= this.dataLimit) {
          this.showPageination = false
        } else {
          this.showPageination = true
        }
      }
    },
    pageNumber: {
      handler () {
        this.selectedPageNumber = this.pageNumber
      }
    },
    resetPagination: {
      handler () {
        if (this.resetPagination) {
          console.log('cehck reset pagi')
          this.start = 0
          this.selectedPageNumber = 1
          this.pageNumber = 1
          this.callApiAsPerData()
        }
      }
    }
  },
  computed: {
  },
  methods: {
    updatePageNumber () {
      if (this.selectedPageNumber > this.totalNoOfPages || this.selectedPageNumber < 1) {
        this.selectedPageNumber = this.pageNumber
      } else {
        this.changePageHandler(this.selectedPageNumber)
      }
    },
    changePageHandler (pageindex) {
      this.pageNumber = pageindex
      this.start =
        this.pageNumber * this.dataLimit - this.dataLimit < 0
          ? 0
          : this.pageNumber * this.dataLimit - this.dataLimit
      console.log('this -- pageNumber--', this.pageNumber)
      this.callApiAsPerData()
    },
    callApiAsPerData () {
      this.$emit('callApiAsPerPagination', {
        offset: this.start,
        limit: this.dataLimit
      })
    },
    nextPage () {
      if (this.pageNumber >= this.totalNoOfPages) {
        return false
      }
      this.pageNumber += 1
      this.start = this.pageNumber * this.dataLimit - this.dataLimit
      this.callApiAsPerData()
    },
    previousPage () {
      if (this.pageNumber !== 1) {
        this.pageNumber -= 1
        this.start = this.pageNumber * this.dataLimit - this.dataLimit
        this.callApiAsPerData()
      }
    },
    dataLimitChangeHandler (data) {
      if (data > this.totalDataCount) {
        this.start = 0
      } else {
        this.pageNumber = Math.ceil(
          (this.start + this.dataLimit) / this.dataLimit
        )
      }
      this.totalNoOfPages = Math.ceil(this.totalDataCount / this.dataLimit)
      this.callApiAsPerData()
    }
  },
  beforeDestroy() {
    this.$root.$off("setPaginationValueAsperVlue");
  },
}
</script>
<style scoped>
button {
  width: 34px !important;
  height: 34px !important;
}
select, input {
  height: 34px !important;
}
.pagination-button {
  border-radius: 3px;
  font-size: 1em;
  text-align: center !important;
}
.selectBoxWidth {
    width: 70px !important;
    padding:0 !important;
    flex-basis: 0 !important;
}
.page-number-input{
    width: 65px;
    display: inline-block;
    height: 30px;
    border: 1px solid #9a9a9a;
    border-radius: 5px;
    padding-left: 5px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number]{
    -moz-appearance: textfield;
}
</style>
